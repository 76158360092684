import { FileValidated } from "@hexa-ui/components";
import { apiDrupal } from "../Api/Api";
import { base_url_quiz } from "../utils/constants";

interface QuizHandleInterface {
  getAllQuizzes: (requestData: getRequest) => Promise<any>
  getQuiz: (requestData: getRequest) => Promise<any>
  createQuiz: (requestData: QuizType) => Promise<any>
  editQuiz: (requestData: QuizType) => Promise<any>
  deleteQuiz: (uuid: string) => Promise<any>
  getResults: (requestData: getRequest) => Promise<any>
  getAllResults: (value: string) => Promise<any>
  createResult: (requestData: QuizType) => Promise<any>
  editResult: (requestData: QuizType) => Promise<any>
  deleteResult: (uuid: string) => Promise<any>
  getAllQuestions: (requestData: getRequest) => Promise<any>
  createQuestion: (requestData: QuestionType) => Promise<any>
  editQuestion: (requestData: QuestionType) => Promise<any>
  deleteQuestion: (uuid: string) => Promise<any>
}

type getRequest = {
  page?: number;
  limit?: number;
  search?: string;
  id?: string;
}

type QuizType = {
  field_cover_image?: {
    id: string
  };
  uuid: string,
  name: string,
  description: string,
  published?: boolean,
  cover_image: FileValidated[],
  show_cover_image?: boolean
}

type QuestionType = {
  quizUuid: string;
  uuidEntity?: string;
  type: string;
  question_id?: string;
  question_name: string;
  question_description: string;
  question_cover_image: FileValidated[];
  field_question_cover_image?: {
    id: string
  },
  feedback_id?: string;
  feedback_name: string;
  feedback_description: string;
  feedback_cover_image: FileValidated[];
  field_feedback_cover_image?: {
    id: string
  },
  answer_id?: string;
  answer_name: string;
  answer_description: string;
  answer_cover_image: FileValidated[];
  field_answer_cover_image?: {
    id: string
  },
  result_id?: string;
  result: string | number;
  points: number;
}

export default function useQuizHandler(): QuizHandleInterface {

  const getAllQuizzes = async (requestData: getRequest) => {
    return await apiDrupal.get(base_url_quiz + '/jsonapi/abinbev_quiz/quiz_personality?page[offset]=' + requestData.page + 'page[limit]=' + requestData.limit + '&include=field_cover_image',
      {
        headers: {
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        }
      }
    )
      .then(response => response.data)
      .catch(error => error);
  }

  const getQuiz = async (requestData: getRequest) => {
    return await apiDrupal.get(base_url_quiz + '/jsonapi/abinbev_quiz/quiz_personality?filter[drupal_internal__id]=' + requestData.id + '&include=field_cover_image',
      {
        headers: {
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        }
      }
    )
      .then(response => response.data)
      .catch(error => error);
  }

  const createQuiz = async (requestData: QuizType) => {
    const createResponse = await apiDrupal.post(`${base_url_quiz}/jsonapi/abinbev_quiz/quiz_personality`,
      {
        "data": {
          "type": "abinbev_quiz--quiz_personality",
          "attributes": {
            "name": requestData.name,
            "field_category": null,
            "field_description": requestData.description,
            "field_show_cover_image": requestData.show_cover_image,
            "status": requestData.published
          },
          "relationships": {
            "abinbev_quiz_type": {
              "data": {
                "type": "abinbev_quiz_type--abinbev_quiz_type",
                "id": "d61c22e2-3ce2-4930-b580-cb0dcfd469f5"
              }
            },
            "user_id": {
              "data": {
                "type": "user--user",
                "id": "7ebdd044-d8b6-43ae-b91d-f9375a0b3a2b"
              }
            }
          }
        }
      }, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        Accept: '*/*'
      }
    })
      .then(response => response.data)
      .catch(error => error.response);

    if (requestData.cover_image && requestData.cover_image.length > 0 && createResponse) {
      return await apiDrupal.post(base_url_quiz + '/jsonapi/abinbev_quiz/quiz_personality/' + createResponse.data.id + '/field_cover_image', requestData.cover_image[0]?.file,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            'Content-Disposition': `file; filename="${requestData.cover_image[0]?.file.name}"`
          }
        }
      )
        .then(response => response.data)
        .catch(error => error);
    }

    return createResponse;
  }

  const editQuiz = async (requestData: QuizType) => {
    const editResponse = await apiDrupal.patch(`${base_url_quiz}/jsonapi/abinbev_quiz/quiz_personality/${requestData.uuid}`,
      {
        "data": {
          "type": "abinbev_quiz--quiz_personality",
          "id": requestData.uuid,
          "attributes": {
            "name": requestData.name,
            "field_category": null,
            "field_description": requestData.description,
            "field_show_cover_image": requestData.show_cover_image,
            "status": requestData.published
          }
        }
      }, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        Accept: '*/*'
      }
    })
      .then(response => response.data)
      .catch(error => error.response);

    if (requestData.cover_image && requestData.cover_image.length > 0 && editResponse) {
      return await apiDrupal.post(base_url_quiz + '/jsonapi/abinbev_quiz/quiz_personality/' + requestData.uuid + '/field_cover_image', requestData.cover_image[0]?.file,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            'Content-Disposition': `file; filename="${requestData.cover_image[0]?.file.name}"`
          }
        }
      )
        .then(response => response.data)
        .catch(error => error);
    } else if (requestData.cover_image && requestData.cover_image.length === 0 && editResponse) {
      return await apiDrupal.delete(base_url_quiz + '/jsonapi/file/file/' + requestData.field_cover_image.id,
        {
          headers: {
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
          }
        }
      )
        .then(response => response.data)
        .catch(error => error);
    }

    return editResponse;
  }


  const deleteQuiz = async (uuid: string) => {
    return await apiDrupal.delete(base_url_quiz + '/jsonapi/abinbev_quiz/quiz_personality/' + uuid,
      {
        headers: {
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        }
      }
    )
      .then(response => response.data)
      .catch(error => error);
  }

  const getResults = async (requestData: getRequest) => {
    return await apiDrupal.get(base_url_quiz + '/jsonapi/abinbev_quiz_result/quiz_result_score?filter[quiz.meta.drupal_internal__target_id]=' + requestData.id + '&page[offset]=' + requestData.page + '&page[limit]=' + requestData.limit + '&include=field_image',
      {
        headers: {
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        }
      }
    )
      .then(response => response.data)
      .catch(error => error);
  }

  const getAllResults = async (value: string) => {
    return await apiDrupal.get(base_url_quiz + '/jsonapi/abinbev_quiz_result/quiz_result_score?filter[name][condition][path]=name&filter[name][condition][operator]=CONTAINS&filter[name][condition][value]=' + value,
      {
        headers: {
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        }
      }
    )
      .then(response => response.data)
      .catch(error => error);
  }

  const createResult = async (requestData: QuizType) => {
    const createResponse = await apiDrupal.post(`${base_url_quiz}/jsonapi/abinbev_quiz_result/quiz_result_score`,
      {
        "data": {
          "type": "abinbev_quiz_result--quiz_result_score",
          "attributes": {
            "name": requestData.name,
            "field_description": requestData.description
          },
          "relationships": {
            "abinbev_quiz_result_type": {
              "data": {
                "type": "abinbev_quiz_result_type--abinbev_quiz_result_type",
                "id": "75a7480c-7608-4f47-b756-4f20a8e0ac78"
              }
            },
            "user_id": {
              "data": {
                "type": "user--user",
                "id": "7ebdd044-d8b6-43ae-b91d-f9375a0b3a2b"
              }
            },
            "quiz": {
              "data": {
                "type": "abinbev_quiz--quiz_personality",
                "id": requestData.uuid
              }
            }
          }
        }
      }, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        Accept: '*/*'
      }
    })
      .then(response => response.data)
      .catch(error => error.response);

    if (requestData.cover_image && requestData.cover_image.length > 0 && createResponse) {
      return await apiDrupal.post(base_url_quiz + '/jsonapi/abinbev_quiz_result/quiz_result_score/' + createResponse.data.id + '/field_image', requestData.cover_image[0]?.file,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            'Content-Disposition': `file; filename="${requestData.cover_image[0]?.file.name}"`
          }
        }
      )
        .then(response => response.data)
        .catch(error => error);
    }

    return createResponse;
  }

  const editResult = async (requestData: QuizType) => {
    const editResponse = await apiDrupal.patch(`${base_url_quiz}/jsonapi/abinbev_quiz_result/quiz_result_score/${requestData.uuid}`,
      {
        "data": {
          "type": "abinbev_quiz_result--quiz_result_score",
          "id": requestData.uuid,
          "attributes": {
              "field_description": requestData.description,
              "name": requestData.name
          }
        }
      }, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        Accept: '*/*'
      }
    })
      .then(response => response.data)
      .catch(error => error.response);

    if (requestData.cover_image && requestData.cover_image.length > 0 && editResponse) {
      return await apiDrupal.post(base_url_quiz + '/jsonapi/abinbev_quiz_result/quiz_result_score/' + requestData.uuid + '/field_image', requestData.cover_image[0]?.file,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            'Content-Disposition': `file; filename="${requestData.cover_image[0]?.file.name}"`
          }
        }
      )
        .then(response => response.data)
        .catch(error => error);
    } else if (requestData.cover_image && requestData.cover_image.length === 0 && editResponse) {
      return await apiDrupal.delete(base_url_quiz + '/jsonapi/file/file/' + requestData.field_cover_image.id,
        {
          headers: {
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
          }
        }
      )
        .then(response => response.data)
        .catch(error => error);
    }

    return editResponse;
  }

  const deleteResult = async (uuid: string) => {
    return await apiDrupal.delete(base_url_quiz + '/jsonapi/abinbev_quiz_result/quiz_result_score/' + uuid,
      {
        headers: {
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        }
      }
    )
      .then(response => response.data)
      .catch(error => error);
  }

  const getAllQuestions = async (requestData: getRequest) => {
    return await apiDrupal.get(base_url_quiz + '/jsonapi/abinbev_quiz_question/abinbev_quiz_question?filter[quiz.meta.drupal_internal__target_id]=' + requestData.id + '&page[offset]=' + requestData.page + '&page[limit]=' + requestData.limit + '&include=field_question.field_image,field_feedback.field_image,field_answers.field_answer.field_image,field_answers.field_results.field_result',
      {
        headers: {
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        }
      }
    )
      .then(response => response.data)
      .catch(error => error);
  }

  const createQuestion = async (requestData: QuestionType) => {
    try {
      const createQuestionResponse = await apiDrupal.post(`${base_url_quiz}/api/v1/quiz/paragraph-create`,
        {
          "data": {
            "type": "paragraph--quiz_simple",
            "attributes": {
              "field_description": requestData.question_description,
              "field_title": requestData.question_name
            }
          }
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            Accept: '*/*'
          }
        }
      );

      const createQuestion = createQuestionResponse.data;

      if (requestData.question_cover_image && requestData.question_cover_image.length > 0 && createQuestion) {
        await apiDrupal.post(base_url_quiz + '/jsonapi/paragraph/quiz_simple/' + createQuestion.data.id + '/field_image', requestData.question_cover_image[0]?.file,
          {
            headers: {
              'Content-Type': 'application/octet-stream',
              Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
              'Content-Disposition': `file; filename="${requestData.question_cover_image[0]?.file.name}"`
            }
          }
        );
      }

      const createFeedbackResponse = await apiDrupal.post(`${base_url_quiz}/api/v1/quiz/paragraph-create`,
        {
          "data": {
            "type": "paragraph--quiz_simple",
            "attributes": {
              "field_description": requestData.feedback_description,
              "field_title": requestData.feedback_name
            }
          }
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            Accept: '*/*'
          }
        }
      );

      const createFeedback = createFeedbackResponse.data;

      if (requestData.feedback_cover_image && requestData.feedback_cover_image.length > 0 && createFeedback) {
        await apiDrupal.post(base_url_quiz + '/jsonapi/paragraph/quiz_simple/' + createFeedback.data.id + '/field_image', requestData.feedback_cover_image[0]?.file,
          {
            headers: {
              'Content-Type': 'application/octet-stream',
              Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
              'Content-Disposition': `file; filename="${requestData.feedback_cover_image[0]?.file.name}"`
            }
          }
        );
      }

      const createAnswerResponse = await apiDrupal.post(`${base_url_quiz}/api/v1/quiz/paragraph-create`,
        {
          "data": {
            "type": "paragraph--quiz_simple",
            "attributes": {
              "field_description": requestData.answer_description,
              "field_title": requestData.answer_name
            }
          }
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            Accept: '*/*'
          }
        }
      );

      const createAnswer = createAnswerResponse.data;

      if (requestData.answer_cover_image && requestData.answer_cover_image.length > 0 && createAnswer) {
        await apiDrupal.post(base_url_quiz + '/jsonapi/paragraph/quiz_simple/' + createAnswer.data.id + '/field_image', requestData.answer_cover_image[0]?.file,
          {
            headers: {
              'Content-Type': 'application/octet-stream',
              Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
              'Content-Disposition': `file; filename="${requestData.answer_cover_image[0]?.file.name}"`
            }
          }
        );
      }

      const createResultResponse = await apiDrupal.post(`${base_url_quiz}/api/v1/quiz/paragraph-create`,
        {
          "data": {
            "type": "paragraph--quiz_answer_personality_result",
            "attributes": {
              "field_points": requestData.points
            },
            "relationships": {
              "field_result": {
                "data": {
                  "type": "abinbev_quiz_result--quiz_result_score",
                  "id": requestData.result
                }
              }
            }
          }
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            Accept: '*/*'
          }
        }
      );

      const createResult = createResultResponse.data;

      const createAnswersResponse = await apiDrupal.post(`${base_url_quiz}/api/v1/quiz/answer-per-bundle-create`,
        {
          "data": {
            "type": "quiz_answer_personality",
            "relationships": {
              "field_answer": {
                "data": {
                  "type": "paragraph--quiz_simple",
                  "id": createAnswer.data.id
                }
              },
              "field_results": {
                "data": {
                  "type": "paragraph--quiz_answer_personality_result",
                  "id": createResult.data.id
                }
              }
            }
          }
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            Accept: '*/*'
          }
        }
      );

      const createAnswers = createAnswersResponse.data;

      const createResponse = await apiDrupal.post(`${base_url_quiz}/api/v1/quiz/question-create`,
        {
          "data": {
            "type": "abinbev_quiz_question--abinbev_quiz_question",
            "attributes": {
              "field_type": requestData.type
            },
            "relationships": {
              "quiz": {
                "data": {
                  "type": "abinbev_quiz--quiz_personality",
                  "id": requestData.quizUuid
                }
              },
              "user_id": {
                "data": {
                  "type": "user--user",
                  "id": "7ebdd044-d8b6-43ae-b91d-f9375a0b3a2b"
                }
              },
              "field_feedback": {
                "data": {
                  "type": "paragraph--quiz_simple",
                  "id": createFeedback.data.id
                }
              },
              "field_question": {
                "data": {
                  "type": "paragraph--quiz_simple",
                  "id": createQuestion.data.id
                }
              },
              "field_answers": {
                "data": {
                  "type": "abinbev_quiz_answer--quiz_answer_personality",
                  "id": createAnswers.data.id
                }
              }
            }
          }
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            Accept: '*/*'
          }
        }
      );

      return createResponse.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  };

  const editQuestion = async (requestData: QuestionType) => {
    try {
      const editQuestionResponse = await apiDrupal.patch(`${base_url_quiz}/jsonapi/paragraph/quiz_simple/${requestData.question_id}`, {
        "data": {
          "type": "paragraph--quiz_simple",
          "id": requestData.question_id,
          "attributes": {
            "field_description": requestData.question_description,
            "field_title": requestData.question_name
          }
        }
      }, {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
          Accept: '*/*'
        }
      });

      const editQuestion = editQuestionResponse.data;

      if (editQuestion) {
        if (requestData.question_cover_image && requestData.question_cover_image.length > 0) {
          await apiDrupal.post(`${base_url_quiz}/jsonapi/paragraph/quiz_simple/${editQuestion.data.id}/field_image`, requestData.question_cover_image[0]?.file, {
            headers: {
              'Content-Type': 'application/octet-stream',
              Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
              'Content-Disposition': `file; filename="${requestData.question_cover_image[0]?.file.name}"`
            }
          });
        } else if (requestData.question_cover_image && requestData.question_cover_image.length === 0 && editQuestion) {
          await apiDrupal.delete(`${base_url_quiz}/jsonapi/file/file/${requestData.field_question_cover_image.id}`, {
            headers: {
              Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            }
          });
        }
      }

      const editFeedbackResponse = await apiDrupal.patch(`${base_url_quiz}/jsonapi/paragraph/quiz_simple/${requestData.feedback_id}`, {
        "data": {
          "type": "paragraph--quiz_simple",
          "id": requestData.feedback_id,
          "attributes": {
            "field_description": requestData.feedback_description,
            "field_title": requestData.feedback_name
          }
        }
      }, {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
          Accept: '*/*'
        }
      });

      const editFeedback = editFeedbackResponse.data;

      if (editFeedback) {
        if (requestData.feedback_cover_image && requestData.feedback_cover_image.length > 0) {
          await apiDrupal.post(`${base_url_quiz}/jsonapi/paragraph/quiz_simple/${editFeedback.data.id}/field_image`, requestData.feedback_cover_image[0]?.file, {
            headers: {
              'Content-Type': 'application/octet-stream',
              Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
              'Content-Disposition': `file; filename="${requestData.feedback_cover_image[0]?.file.name}"`
            }
          });
        } else if (requestData.feedback_cover_image && requestData.feedback_cover_image.length === 0 && editFeedback) {
          await apiDrupal.delete(`${base_url_quiz}/jsonapi/file/file/${requestData.field_feedback_cover_image.id}`, {
            headers: {
              Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            }
          });
        }
      }

      const editAnswerResponse = await apiDrupal.patch(`${base_url_quiz}/jsonapi/paragraph/quiz_simple/${requestData.answer_id}`, {
        "data": {
          "type": "paragraph--quiz_simple",
          "id": requestData.answer_id,
          "attributes": {
            "field_description": requestData.answer_description,
            "field_title": requestData.answer_name
          }
        }
      }, {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
          Accept: '*/*'
        }
      });

      const editAnswer = editAnswerResponse.data;

      if (editAnswer) {
        if (requestData.answer_cover_image && requestData.answer_cover_image.length > 0) {
          await apiDrupal.post(`${base_url_quiz}/jsonapi/paragraph/quiz_simple/${editAnswer.data.id}/field_image`, requestData.answer_cover_image[0]?.file, {
            headers: {
              'Content-Type': 'application/octet-stream',
              Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
              'Content-Disposition': `file; filename="${requestData.answer_cover_image[0]?.file.name}"`
            }
          });
        } else if (requestData.answer_cover_image && requestData.answer_cover_image.length === 0 && editAnswer) {
          await apiDrupal.delete(`${base_url_quiz}/jsonapi/file/file/${requestData.field_answer_cover_image.id}`, {
            headers: {
              Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
            }
          });
        }
      }

      const editResultResponse = await apiDrupal.patch(`${base_url_quiz}/jsonapi/paragraph/quiz_answer_personality_result/${requestData.result_id}`,
        {
          "data": {
            "type": "paragraph--quiz_answer_personality_result",
            "id": requestData.result_id,
            "attributes": {
                "field_points": requestData.points
            },
            "relationships": {
                "field_result": {
                    "data": {
                        "type": "abinbev_quiz_result--quiz_result_score",
                        "id": requestData.result
                    }
                }
            }
          }
        }, {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
          Accept: '*/*'
        }
      });

      const editResponse = await apiDrupal.patch(`${base_url_quiz}/jsonapi/abinbev_quiz_question/abinbev_quiz_question/${requestData.uuidEntity}`, {
        "data": {
          "type": "abinbev_quiz_question--abinbev_quiz_question",
          "id": requestData.uuidEntity,
          "attributes": {
            "field_type": requestData.type
          }
        }
      }, {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
          Accept: '*/*'
        }
      });

      return editResponse.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  };

  const deleteQuestion = async (uuid: string) => {
    return await apiDrupal.delete(base_url_quiz + '/jsonapi/abinbev_quiz_question/abinbev_quiz_question/' + uuid,
      {
        headers: {
          Authorization: `Basic YmVlc2FkbWluOmFrLTgjNT1OSl1mOV00bg==`,
        }
      }
    )
      .then(response => response.data)
      .catch(error => error);
  }

  return { getAllQuizzes, getQuiz, createQuiz, editQuiz, deleteQuiz, getResults, getAllResults, createResult, editResult,
    deleteResult, getAllQuestions, createQuestion, editQuestion, deleteQuestion }
}
