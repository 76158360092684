import { Card, FileValidated, Grid, Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useContext, useState } from 'react';
import useWindowDimensions from '../../hook/useWindowDimensions';
import StyledInput from '../../components/Input/Input';
import StyledToggle from '../../components/Toggle/Toggle';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import StyledButton from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import verifyImgType from '../../utils/verifyImgType';
import { AlertContext } from '../../contexts/alert.context';
import StyledTextarea from '../../components/Textarea/Textarea';
import useQuizHandler from '../../hook/useQuizHandler';

type QuizType = {
  name: string;
  description: string;
  published: boolean;
  cover_image: FileValidated[];
  show_cover_image: boolean;
}

const StyledPaddding = styled('div', {
  paddingBottom: '1rem',
});

const AddQuiz: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(AlertContext);
  const [fileError, setFileError] = useState(false);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [formQuiz, setFormQuiz] = useState<QuizType>({
    name: '',
    description: '',
    published: false,
    cover_image: [],
    show_cover_image: false,
  });
  const { createQuiz } = useQuizHandler();

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  const sizeValidation = (file) => {
    if (file[0].file.size > 26214400) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
      setFormQuiz({ ...formQuiz, cover_image: e });
    }
  };

  const handleSave = async () => {
    if (!formQuiz?.name) {
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
      setHasEmptyField(true);
      return;
    }

    setHasEmptyField(false);

    await createQuiz({
      name: formQuiz.name,
      description: formQuiz.description,
      published: formQuiz.published,
      cover_image: formQuiz.cover_image,
      show_cover_image: formQuiz.show_cover_image,
      uuid: ''
    }).then((res) => {
      console.log(res, res?.data?.errors)
      if (res?.data?.errors) {
        addToast({
          message: 'An error occurred while create the quiz.',
          type: 'error',
        });
      } else {
        addToast({
          message: 'Quiz successfuly created.',
          type: 'success',
        });
        navigate(-1);
      }
    })
  }

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '0.5rem' }}>
          Add Quiz Personality
        </Heading>
      </Grid.Item>

      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="medium" css={{ width: '100%' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              padding: '1.5rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="Name*"
                    width={'30vw'}
                    hint="The name of the Quiz entity."
                    placeholder=""
                    value={formQuiz?.name}
                    onChange={(e) => setFormQuiz({ ...formQuiz, name: e.currentTarget.value })}
                    errorText='Name is required'
                    hasError={hasEmptyField && !formQuiz?.name}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledTextarea
                    label="Description"
                    hint="&nbsp;"
                    placeholder="Insert your text here"
                    value={formQuiz?.description}
                    width={'30vw'}
                    maxLength={150}
                    characterCounter
                    style={{
                      height: '90px',
                      resize: 'none',
                    }}
                    onChange={(e) => setFormQuiz({ ...formQuiz, description: e.currentTarget.value })}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledToggle
                    title="Published"
                    description="A boolean indicating whether the Quiz is published."
                    value={formQuiz?.published}
                    onChange={() => setFormQuiz({ ...formQuiz, published: !formQuiz?.published })}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledFileUploader
                    value={formQuiz?.cover_image}
                    title="Cover Image"
                    accept=".png,.jpg,.jpeg,.gif"
                    maxFileSize={26214400}
                    message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                    error={ fileError && {
                      message: 'The selected file cannot be uploaded.',
                    }}
                    width="30vw"
                    onDrop={(e) => {
                      verifiedFile(e);
                    }}
                    onClean={(file) => {
                      setFileError(false);
                      setFormQuiz({
                        ...formQuiz,
                            cover_image: [],
                      });
                    }}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledToggle
                    title="Show cover image before Quiz"
                    value={formQuiz?.show_cover_image}
                    onChange={() => setFormQuiz({ ...formQuiz, show_cover_image: !formQuiz?.show_cover_image })}
                  />
                </StyledPaddding>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '20px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={handleSave}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </Grid.Item>
    </>
  );
};

export default AddQuiz;
