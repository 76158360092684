import { Card, Grid, LoadingDots, Image, FileValidated } from '@hexa-ui/components';
import { Edit2, Trash2 } from '@hexa-ui/icons';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FlexContainer from '../../../components/FlexContainer';
import InfoField, { InfoButtonText, InfoCardTitle } from '../../../components/InfoField/InfoField';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import StyledToggle from '../../../components/Toggle/Toggle';
import moment from 'moment';
import { base_url } from '../../../utils/constants';
import useQuizHandler from '../../../hook/useQuizHandler';

type QuizType = {
  id: string;
  uuid: string;
  title: string;
  published: string;
  published_value: boolean;
  description: string;
  last_edit: string;
  show_cover_image: boolean;
  cover_image: FileValidated[];
  field_cover_image: string;
}

const QuizTab: React.FC = () => {
  const { getQuiz, deleteQuiz } = useQuizHandler();
  const [data, setData] = useState<QuizType>();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const { id } = params;
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState({
    id: id
  })

  const fetchData = async () => {
    setLoading(true)
    await getQuiz(requestData)
      .then(async (res) => {
        setData({
          id: res.data[0].attributes.drupal_internal__id,
          uuid: res.data[0].id,
          title: res.data[0].attributes.name,
          published: res.data[0].attributes.status === true ? 'On' : 'Off',
          published_value: res.data[0].attributes.status,
          description: res.data[0].attributes.field_description,
          last_edit: res.data[0].attributes.changed,
          show_cover_image: res.data[0].attributes.field_show_cover_image,
          cover_image: res.included ? res.included[0] : '',
          field_cover_image: res.included ? res.included[0].attributes.uri.url : '',
        })
      })
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function handleDelete() {
    deleteQuiz(data.uuid)
    navigate(-1);
  }

  return data ? (
    <Card elevated={'medium'} border={'large'} css={{ padding: '2rem 0' }}>
      <FlexContainer
        width="100%"
        display="inline-flex"
        justifyContent="space-between"
        alignItems="center"
        padding="0 2rem"
        flexWrap="wrap"
      >
        <InfoCardTitle title={data.title} />
        <FlexContainer display="inline-flex" gap="0.5rem">
          <InfoButtonText
            size="small"
            css={{ backgroundColor: '#E9F1FF', borderRadius: '24px', padding: '0.25rem 0.5rem' }}
          >
            {data.published_value ? 'Published' : 'Unpublished'}
          </InfoButtonText>
          <InfoButtonText
            icon={Edit2}
            iconPosition="leading"
            onClick={() =>
              navigate(
                '/bees-games/quiz/quiz-details/' + id + '/edit',
                {
                  state: data,
                }
              )
            }
          >
            Edit
          </InfoButtonText>
          <ModalDelete
            handleDelete={() => handleDelete()}
            trigger={
              <InfoButtonText icon={Trash2} iconPosition="leading">
                Delete
              </InfoButtonText>
            }
            title="Delete quiz?"
            description={
              <>
                Do you really wish to delete this quiz? <br />
                If the quiz is published, users won’t be able to play anymore and this action
                can’t be undone.
              </>
            }
          />
        </FlexContainer>
      </FlexContainer>
      <br />
      <Grid.Container type="fluid" style={{ rowGap: '1rem' }}>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="ID" text={String(data.id)} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Name" text={data.title} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Description" text={data.description} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Last edit" text={moment(data.last_edit).format('DD/MM/YYYY HH:mm')} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <InfoField title="Cover Image" />
          <Image src={base_url + data.field_cover_image} width={'300px'} height={'250px'} containerCss={{ width: '100%', marginBottom: '0.3rem' }} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Show Cover Image" text={<StyledToggle value={data.show_cover_image} checked={data.show_cover_image} size={'small'} onChange={() => { }} />} />
        </Grid.Item>
      </Grid.Container>
    </Card>
  ) : (
    <Card elevated={'large'} border={'large'} css={{ padding: '1rem 0' }}>
      <FlexContainer display="flex" height="150px" justifyContent="center" alignItems="center">
        <LoadingDots size="large" />
      </FlexContainer>
    </Card>
  );
};

export default QuizTab;
