import { useEnvService } from 'admin-portal-shared-services';

const sit_url = 'https://promoadmin.dev-abinbev.acsitefactory.com'
const uat_url = 'https://promoadmin.test-abinbev.acsitefactory.com'
const prod_url = 'https://adminbees.games'

const sit_url_quiz = 'https://quizadmin.dev-abinbev.acsitefactory.com'
const uat_url_quiz = 'https://quizadmin.test-abinbev.acsitefactory.com'
const prod_url_quiz = 'https://quizadmin.abinbev.acsitefactory.com'

const env = useEnvService().getEnv()

export const base_url = env === "QA" || env === "SIT" ? sit_url : env === "UAT" ? uat_url : env === "PROD" ? prod_url : uat_url

export const base_url_quiz = env === "QA" || env === "SIT" ? sit_url_quiz : env === "UAT" ? uat_url_quiz : env === "PROD" ? prod_url_quiz : uat_url_quiz
