import AcceptanceTab from '../tabs/AcceptanceTab';
import AnalyticsTab from '../tabs/AnalyticsTab';
import CampaignTab from '../tabs/CampaignTab';
import ChallengeTab from '../tabs/ChallengesTab';
import ChancesResetTab from '../tabs/ChancesResetTab';
import CustomSettingsTab from '../tabs/CustomSettingsTab';
import DigitalPrizeCodeTab from '../tabs/DigitalPrizeCodeTab';
import LevelsTab from '../tabs/LevelsTab';
import LuckyNumbersTab from '../tabs/LuckyNumbersTab';
import PlayerProgressTab from '../tabs/PlayerProgressTab';
import PlayerScoreBlvlTab from '../tabs/PlayerScoreBlvlTab';
import PlayersTab from '../tabs/Players';
import PrizesTab from '../tabs/PrizesTab';
import RankingTab from '../tabs/RankingTab';
import TimeWindowTab from '../tabs/TimeWindowTab';
import TotalScoreTab from '../tabs/TotalScoreTab';

const CampaignDetailTabs = {
  empty: [{ component: CampaignTab, hash: '#campaign', title: 'Campaign' }],
  bee_run: [
    { component: CampaignTab, hash: '#campaign', title: 'Campaign' },
    { component: CustomSettingsTab, hash: '#pdfsettings', title: 'PDF settings' },
    /* { component: DigitalPrizeCodeTab, hash: '#digitalprizecodes', title: 'Digital prize codes' }, */
    /* { component: PrizesTab, hash: '#prizes', title: 'Prizes' }, */
    { component: ChallengeTab, hash: '#challenges', title: 'Challenges' },
    { component: PlayersTab, hash: '#players', title: 'Players' },
    { component: AcceptanceTab, hash: '#acceptance', title: 'Acceptance' },
    { component: AnalyticsTab, hash: '#analytics', title: 'Analytics' },
    { component: ChancesResetTab, hash: '#chancesReset', title: 'Chances reset' },
    { component: TotalScoreTab, hash: '#totalscore', title: 'Total Score' }
  ],
  spinning_wheel: [
    { component: CampaignTab, hash: '#campaign', title: 'Campaign' },
    { component: CustomSettingsTab, hash: '#pdfsettings', title: 'PDF settings' },
    { component: DigitalPrizeCodeTab, hash: '#digitalprizecodes', title: 'Digital prize codes' },
    { component: PlayersTab, hash: '#players', title: 'Players' },
    { component: ChallengeTab, hash: '#challenges', title: 'Challenges' },
    { component: PrizesTab, hash: '#prizes', title: 'Prizes' },
    { component: LuckyNumbersTab, hash: '#luckynumbers', title: 'Lucky numbers' },
    { component: TimeWindowTab, hash: '#timeWindow', title: 'Time window' },
    { component: AcceptanceTab, hash: '#acceptance', title: 'Acceptance' },
    { component: AnalyticsTab, hash: '#analytics', title: 'Analytics' },
    { component: ChancesResetTab, hash: '#chancesReset', title: 'Chances reset' },
  ],
  mix_and_match: [
    { component: CampaignTab, hash: '#campaign', title: 'Campaign' },
    { component: CustomSettingsTab, hash: '#pdfsettings', title: 'PDF settings' },
    { component: PlayersTab, hash: '#players', title: 'Players' },
    { component: ChallengeTab, hash: '#challenges', title: 'Challenges' },
    { component: AcceptanceTab, hash: '#acceptance', title: 'Acceptance' },
    { component: LevelsTab, hash: '#levels', title: 'Levels' },
    { component: PlayerScoreBlvlTab, hash: '#playerScoreByLevel', title: 'Player score by level' },
    { component: PlayerProgressTab, hash: '#playerprogress', title: 'Player progress' },
    { component: AnalyticsTab, hash: '#analytics', title: 'Analytics' },
    { component: ChancesResetTab, hash: '#chancesReset', title: 'Chances reset' },
  ],

  bottle_crush: [
    { component: CampaignTab, hash: '#campaign', title: 'Campaign' },
    { component: CustomSettingsTab, hash: '#pdfsettings', title: 'PDF settings' },
    { component: PlayersTab, hash: '#players', title: 'Players' },
    { component: ChallengeTab, hash: '#challenges', title: 'Challenges' },
    { component: AcceptanceTab, hash: '#acceptance', title: 'Acceptance' },
    { component: LevelsTab, hash: '#levels', title: 'Levels' },
    { component: PlayerScoreBlvlTab, hash: '#playerScoreByLevel', title: 'Player score by level' },
    { component: PlayerProgressTab, hash: '#playerprogress', title: 'Player progress' },
    { component: RankingTab, hash: '#rankings', title: 'Rankings' },
    { component: AnalyticsTab, hash: '#analytics', title: 'Analytics' },
    { component: ChancesResetTab, hash: '#chancesReset', title: 'Chances reset' },
  ],
  penalty_kick: [
    { component: CampaignTab, hash: '#campaign', title: 'Campaign' },
    { component: CustomSettingsTab, hash: '#pdfsettings', title: 'PDF settings' },
    { component: DigitalPrizeCodeTab, hash: '#digitalprizecodes', title: 'Digital prize codes' },
    { component: PlayersTab, hash: '#players', title: 'Players' },
    { component: ChallengeTab, hash: '#challenges', title: 'Challenges' },
    { component: PrizesTab, hash: '#prizes', title: 'Prizes' },
    { component: LuckyNumbersTab, hash: '#luckynumbers', title: 'Lucky numbers' },
    { component: TimeWindowTab, hash: '#timeWindow', title: 'Time window' },
    { component: AcceptanceTab, hash: '#acceptance', title: 'Acceptance' },
    { component: AnalyticsTab, hash: '#analytics', title: 'Analytics' },
    { component: ChancesResetTab, hash: '#chancesReset', title: 'Chances reset' },
  ],
  instant_card: [
    { component: CampaignTab, hash: '#campaign', title: 'Campaign' },
    { component: CustomSettingsTab, hash: '#pdfsettings', title: 'PDF settings' },
    { component: DigitalPrizeCodeTab, hash: '#digitalprizecodes', title: 'Digital prize codes' },
    { component: PlayersTab, hash: '#players', title: 'Players' },
    { component: ChallengeTab, hash: '#challenges', title: 'Challenges' },
    { component: PrizesTab, hash: '#prizes', title: 'Prizes' },
    { component: LuckyNumbersTab, hash: '#luckynumbers', title: 'Lucky numbers' },
    { component: TimeWindowTab, hash: '#timeWindow', title: 'Time window' },
    { component: AcceptanceTab, hash: '#acceptance', title: 'Acceptance' },
    { component: AnalyticsTab, hash: '#analytics', title: 'Analytics' },
    { component: ChancesResetTab, hash: '#chancesReset', title: 'Chances reset' },
  ],
  virtual_cup_mug: [
    { component: CampaignTab, hash: '#campaign', title: 'Campaign' },
    { component: CustomSettingsTab, hash: '#pdfsettings', title: 'PDF settings' },
    { component: PrizesTab, hash: '#prizes', title: 'Prizes' },
    { component: DigitalPrizeCodeTab, hash: '#digitalprizecodes', title: 'Digital prize codes' },
    { component: PlayersTab, hash: '#players', title: 'Players' },
    { component: ChallengeTab, hash: '#challenges', title: 'Challenges' },
    { component: LuckyNumbersTab, hash: '#luckynumbers', title: 'Lucky numbers' },
    { component: TimeWindowTab, hash: '#timeWindow', title: 'Time window' },
    { component: AcceptanceTab, hash: '#acceptance', title: 'Acceptance' },
    { component: AnalyticsTab, hash: '#analytics', title: 'Analytics' },
    { component: ChancesResetTab, hash: '#chancesReset', title: 'Chances reset' },
  ],
  soccer_competition: [
    { component: CampaignTab, hash: '#campaign', title: 'Campaign' },
    { component: CustomSettingsTab, hash: '#pdfsettings', title: 'PDF settings' },
    { component: PlayersTab, hash: '#players', title: 'Players' },
    //{ component: ChallengeTab, hash: '#challenges', title: 'Challenges' }
    //{ component: GuessesTab, hash: '#guesses', title: 'Guesses' },
    { component: AcceptanceTab, hash: '#acceptance', title: 'Acceptance' },
    //{ component: RankingsBolaoTab, hash: '#rankingsbolao', title: 'Rankings' },
    //{ component: InstantPrizesTab, hash: '#instantprizes', title: 'Instant Prizes' }
    //{ component: PlayerPrizesTab, hash: '#playerprizes', title: 'Player Prizes' },
    { component: AnalyticsTab, hash: '#analytics', title: 'Analytics' },
    { component: ChancesResetTab, hash: '#chancesReset', title: 'Chances reset' },
  ],
};

export default CampaignDetailTabs;
