import { FileValidated, Grid, Select } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import StyledButton from '../../../../../components/Button/Button';
import StyledFileUploader from '../../../../../components/FileUploader/FileUploader';
import FlexContainer from '../../../../../components/FlexContainer';
import StyledInput from '../../../../../components/Input/Input';
import StyledSelect from '../../../../../components/Select/Select';
import StyledTextarea from '../../../../../components/Textarea/Textarea';
import { AlertContext } from '../../../../../contexts/alert.context';
import { GameSettingsContext } from '../../../../../contexts/gameSettings.context';
import useWindowScale from '../../../../../hook/useWindowScale';
import { AppDispatch, useAppSelector } from '../../../../../store';
import { CampaignType, PrizeType } from '../../../../../store/dataTypes';
import { UploadImage } from '../../../../../store/stock/FileUploadReducer';
import { POST_PRIZE } from '../../../../../store/stock/prizeCreationReducer';
import verifyImgType from '../../../../../utils/verifyImgType';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
}
const TabCreateNewPrize: React.FC<props> = ({ form, setForm }) => {
  const { gameSettingsState, setGameSettingsState } = useContext(GameSettingsContext);
  const { addToast } = useContext(AlertContext);
  const isAugmentedScale = useWindowScale();
  const uploadedData = useAppSelector((state) => state.uploadFile.data);
  const dispatch = useDispatch<AppDispatch>();
  const [formPrize, setFormPrize] = useState<PrizeType>(undefined);
  const [fileError, setFileError] = useState(false);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [fileIMG, setFileIMG] = useState<FileValidated[]>([]);

  const [loading, setLoading] = useState(false);

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  async function getImageID(file: FileValidated[]) {
    setFileError(false);
    setGameSettingsState({
      ...gameSettingsState,
      createPrize: {
        ...gameSettingsState.createPrize,
        image: file,
      },
    });
    await dispatch(
      UploadImage({
        file: file[0].file,
        mimetype: file[0].file.type,
        filename: file[0].file.name,
      })
    ).catch(() => addToast({ message: 'Error loading image.', type: 'error' }));
  }

  useEffect(() => {
    if (!uploadedData) {
      return;
    }

    handleChangePrize('field_image', uploadedData?.fid[0]?.value);
    // changeStateForm(setFormPrize, formPrize, 'field_image', uploadedData.fid[0].value);
  }, [uploadedData]);

  async function handleSavePrize() {
    let errors = {
      formPrize: !gameSettingsState?.createPrize?.prize ? true : false,
      name:
        form?.type?.target_id === 'spinning_wheel'
          ? !gameSettingsState?.createPrize?.prize?.name
          : false,
      sku: !gameSettingsState?.createPrize?.prize?.sku ? true : false,
      field_type: !gameSettingsState?.createPrize?.prize?.field_type ? true : false,
      field_image: !gameSettingsState?.createPrize?.prize?.field_image ? true : false,
      image: !gameSettingsState?.createPrize?.image?.length ? true : false,
      description: !gameSettingsState?.createPrize?.prize?.description ? true : false,
    };

    if (
      !gameSettingsState?.createPrize?.prize ||
      Object.values(errors).some((v: boolean) => v === true)
    ) {
      addToast({ message: 'Please fill in all fields to continue', type: 'error' });
      setHasEmptyField(true);
      return;
    }

    setLoading(true);
    await dispatch(
      POST_PRIZE({
        name: gameSettingsState?.createPrize?.prize?.name,
        sku: gameSettingsState?.createPrize?.prize?.sku,
        field_type: gameSettingsState?.createPrize?.prize?.field_type,
        field_image: Number(gameSettingsState?.createPrize?.prize?.field_image),
        description: gameSettingsState?.createPrize?.prize?.description,
      })
    )
      .then(() => {
        addToast({
          message: 'prize created successfully.',
          type: 'success',
        });
        setGameSettingsState({
          ...gameSettingsState,
          createPrize: {
            prize: null,
            image: [],
          },
        });
        setHasEmptyField(false);
      })
      .catch(() => {
        addToast({
          message: 'An error occurred while creating the prize.',
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
    setHasEmptyField(false);
  }

  function handleChangePrize(key: string, value: any) {
    setGameSettingsState({
      ...gameSettingsState,
      createPrize: {
        ...gameSettingsState?.createPrize,
        prize: {
          ...gameSettingsState?.createPrize?.prize,
          [key]: value,
        },
      },
    });
  }

  const sizeValidation = (file: FileValidated[]) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
      setFileIMG(e);
      getImageID(e);
    }
  };

  return (
    <Grid.Item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      style={{ rowGap: '1rem', flexDirection: 'column' }}
    >
      <FlexContainer display="flex" flexWrap="wrap" gap="1rem">
        <StyledInput
          label="Prize Name*"
          placeholder="Insert your text here"
          size="large"
          width="20%"
          value={gameSettingsState?.createPrize?.prize?.name || ''}
          onChange={(e) => handleChangePrize('name', e.currentTarget.value)}
          hasError={hasEmptyField && !gameSettingsState?.createPrize?.prize?.name}
          errorText="Name is required."
        />
        <StyledInput
          label="Voucher ID*"
          placeholder="Insert your text here"
          size="large"
          width="20%"
          value={gameSettingsState?.createPrize?.prize?.sku || ''}
          onChange={(e) => handleChangePrize('sku', e.currentTarget.value)}
          hasError={hasEmptyField && !gameSettingsState?.createPrize?.prize?.sku}
          errorText="Sku is required."
        />
        <StyledSelect
          error={
            hasEmptyField && !gameSettingsState.createPrize?.prize?.field_type
              ? 'Type is required'
              : ''
          }
          width={'20%'}
          label="Category*"
          value={gameSettingsState?.createPrize?.prize?.field_type || ''}
          onChange={(e) => handleChangePrize('field_type', e)}
        >
          <Select.Option value="physical">Physical</Select.Option>
          <Select.Option value="digital">Digital</Select.Option>
        </StyledSelect>
      </FlexContainer>
      {/* <StyledSelect width={'20%'} label='Type'>
              <Select.Option value='option1'>Option 1</Select.Option>
              <Select.Option value='option2'>Option 2</Select.Option>
          </StyledSelect> */}
      <FlexContainer display="flex" justifyContent="space-between">
        <StyledFileUploader
          value={gameSettingsState?.createPrize?.image}
          width="47.5%"
          title="Prize thumbnail*"
          accept=".png,.jpg,.jpeg,.gif"
          maxFileSize={1024 * 1024 * 5}
          message="Allowed PNG, JPG, JPEG, GIF format and must be less than 1MB. Images must be exactly 290x188 pixels."
          error={
            (fileError ||
              (hasEmptyField && !gameSettingsState?.createPrize?.prize?.field_image)) && {
              message: 'The selected file cannot be uploaded or is missing.',
            }
          }
          onChange={(file) => {}}
          onDrop={(e) => {
            verifiedFile(e);
          }}
          onClean={(file) => {
            setFileError(false);
            setGameSettingsState({
              ...gameSettingsState,
              createPrize: {
                ...gameSettingsState.createPrize,
                image: [],
                prize: {
                  ...gameSettingsState.createPrize.prize,
                  field_image: null,
                },
              },
            });
            // setFormPrize({
            // ...formPrize,
            // field_image: undefined,
            // });
          }}
          maxHeight="275px"
          type="image"
        />
        <StyledTextarea
          label="Description*"
          placeholder="Insert your text here"
          width={'47.5%'}
          maxLength={150}
          characterCounter
          style={{
            height: isAugmentedScale ? '294px' : '280px',
            resize: 'none',
          }}
          value={gameSettingsState?.createPrize?.prize?.description || ''}
          onChange={(e) => handleChangePrize('description', e.currentTarget.value)}
          hasError={hasEmptyField && !gameSettingsState?.createPrize?.prize?.description}
          errorText="Description is required."
        />
      </FlexContainer>
      <StyledButton
        isLoading={loading}
        leading
        icon={Plus}
        onClick={() => handleSavePrize()}
        css={{
          marginLeft: 'auto',
        }}
      >
        Create prize
      </StyledButton>
    </Grid.Item>
  );
};
export default TabCreateNewPrize;
