import { Card, FileValidated, Grid, Heading, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useContext, useState } from 'react';
import StyledInput from '../../components/Input/Input';
import SearchDropdown from '../../components/SearchDropdown/SearchDropdown';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import { base_url } from '../../utils/constants';
import StyledButton from '../../components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import verifyImgType from '../../utils/verifyImgType';
import { AlertContext } from '../../contexts/alert.context';
import StyledTextarea from '../../components/Textarea/Textarea';
import useQuizHandler from '../../hook/useQuizHandler';
import QuantifierLabeled from '../../components/QuantifierLabeled/QuantifierLabeled';

type ResultType = {
  uuid: string;
  name: string;
  description: string;
  cover_image: FileValidated[];
}

const StyledPaddding = styled('div', {
  paddingBottom: '1rem',
});

const AddResult: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(AlertContext);
  const [fileError, setFileError] = useState(false);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [formResult, setFormResult] = useState<ResultType>({
    name: '',
    description: '',
    cover_image: [],
    uuid: state.campaign_uuid,
  });
  const { createResult } = useQuizHandler();

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  const trivia = false

  const sizeValidation = (file) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
      setFormResult({ ...formResult, cover_image: e });
    }
  };

  const handleSave = async () => {
    if (!formResult?.name) {
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
      setHasEmptyField(true);
      return;
    }

    setHasEmptyField(false);

    await createResult({
      name: formResult.name,
      description: formResult.description,
      cover_image: formResult.cover_image,
      uuid: formResult.uuid
    }).then((res) => {
      console.log(res, res?.data?.errors)
      if (res?.data?.errors) {
        addToast({
          message: 'An error occurred while creating the result.',
          type: 'error',
        });
      } else {
        addToast({
          message: 'Result successfuly created.',
          type: 'success',
        });
        navigate(-1);
      }
    })
  }

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '0.5rem' }}>
          Add Score
        </Heading>
      </Grid.Item>

      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="medium" css={{ width: '100%' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              padding: '1.5rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPaddding>
                  <SearchDropdown
                    width=""
                    label="Quiz*"
                    hint="&nbsp;"
                    value={state.campaign_name}
                    disabled={true}
                    placeholder=""
                    onSearch={() => {
                      throw new Error('Function not implemented.')
                    }}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledInput
                    size="large"
                    label="Title*"
                    width={''}
                    hint="The name of the Ab InBev Result entity."
                    value={formResult?.name}
                    onChange={(e) => setFormResult({ ...formResult, name: e.currentTarget.value })}
                    errorText='Name is required'
                    hasError={hasEmptyField && !formResult?.name}
                    placeholder=""
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledTextarea
                    label="Description"
                    hint="&nbsp;"
                    placeholder="Insert your text here"
                    value={formResult?.description}
                    width={'100%'}
                    maxLength={150}
                    characterCounter
                    style={{
                      height: '90px',
                      resize: 'none',
                    }}
                    onChange={(e) => setFormResult({ ...formResult, description: e.currentTarget.value })}
                  />
                </StyledPaddding>
                <StyledPaddding>
                  <StyledFileUploader
                    value={formResult?.cover_image}
                    title="Image"
                    url={base_url + ''}
                    accept=".png,.jpg,.jpeg,.gif"
                    maxFileSize={1000000}
                    message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                    width="30vw"
                    onDrop={(e) => {
                      verifiedFile(e);
                    }}
                    onClean={(file) => {
                      setFileError(false);
                      setFormResult({
                        ...formResult,
                            cover_image: [],
                      });
                    }}
                  />
                </StyledPaddding>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '20px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={handleSave}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </Grid.Item>
    </>
  );
};

export default AddResult;
