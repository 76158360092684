import { FileValidated, Grid, Paragraph, Select, Toggle } from '@hexa-ui/components';
import moment from 'moment';
import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import StyledAlert from '../components/Alert/Alert';
import FlexContainer from '../components/FlexContainer';
import { StyledParagraph } from '../components/Heading/Typography';
import { InfoCardTitle } from '../components/InfoField/InfoField';
import StyledInput from '../components/Input/Input';
import PageTitle from '../components/PageTitle/PageTitle';
import StyledSelect from '../components/Select/Select';
import StyledToggle from '../components/Toggle/Toggle';
import { countryType } from '../components/layout/Layout';
import { AlertContext } from '../contexts/alert.context';
import useValidationHandler from '../hook/useValidation';
import useWindowScale from '../hook/useWindowScale';
import { AppDispatch, useAppSelector } from '../store';
import { brandType } from '../store/dataTypes/brand.type';
import { CampaignType } from '../store/dataTypes/campaign.type';
import { categoryType } from '../store/dataTypes/category.type';
import { FETCH_BRANDS } from '../store/stock/BrandReducer';
import { FETCH_CAMPAIGN_DETAILS, PATCH_CAMPAIGN_DETAILS } from '../store/stock/CampaignDetailsReducer';
import { FETCH_CATEGORIES } from '../store/stock/CategoryReducer';
import { UploadImage } from '../store/stock/FileUploadReducer';
import { changeStateForm } from '../utils/functions';
import base64FileConverter from '../utils/toBase64';
import { ErrorText } from '@hexa-ui/components/dist/declarations/src/lib/checkbox/Checkbox.styles';
import { warningMessages } from '../utils/validationMessages';
import OptInModal from '../components/OptInModal/OptInModal';
import useChallengeHandler from '../hook/useChallengeHandler';
import { getJsonApiData } from '../utils/formatData';
import QuantifierLabeled from '../components/QuantifierLabeled/QuantifierLabeled';

interface IEditCampaign {
  form: CampaignType;
  setForm: SetStateAction<any>;
  isCreateForm?: boolean;
  isEdit?: boolean;
  step?: number;
  stepIncompleted?: boolean;
}

interface ValidationData {
  time_window_empty?: string;
  time_window_total?: string;
  time_window_x_prize_quantity_mismatch?: string;
}

const EditCampaignModule: React.FC<IEditCampaign> = ({ form, setForm, stepIncompleted, isCreateForm, isEdit }) => {
  const isAugmentedScale = useWindowScale();
  const { getValidation } = useValidationHandler();
  const { addToast } = useContext(AlertContext);
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const { state } = useLocation();
  const uploadedData = useAppSelector((state) => state.uploadFile.data);
  const brandData: brandType[] = useAppSelector((state) => state.brands.data);
  const categoryData: categoryType[] = useAppSelector((state) => state.categories.data);
  const countriesPromo: countryType[] = useAppSelector((state) => state.country.data);
  const campaignData: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const newCampaignData: CampaignType = useAppSelector(
    (state) => state.campaignDetails.newCampaign
  );
  const [validationData, setValidationData] = useState<ValidationData | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [toastAfterModal, setToastAfterModal] = useState<{ message: string; type: 'success' | 'warning' | 'error' } | null>(null);
  const { getChallengesByCampaign } = useChallengeHandler();
  const [challenges, setChallenges] = useState<any[]>([]);

  const fetchData = async () => {
    await getChallengesByCampaign(campaignData?.uuid, { page: 0, limit: 1 }, false)
      .then(res => {
        const formatData = getJsonApiData(res, true)
        setChallenges(formatData.data)
      })
  };

  useEffect(() => {
    fetchData()
    async function init() {
      dispatch(FETCH_BRANDS());
      dispatch(FETCH_CATEGORIES());
      isEdit && dispatch(FETCH_CAMPAIGN_DETAILS({ id: params?.id }));
      const response = await getValidation(params.id);
      setValidationData(response.data);
    }
    init();
    if (form.player_progress_expiration_time === undefined) {
      changeStateForm(setForm, form, 'player_progress_expiration_time', form.player_progress_expiration_time = 1);
    }
  }, []);
  async function getImageID(file: FileValidated[]) {
    const convertedImage = await base64FileConverter(file[0].file);

    await dispatch(
      UploadImage({
        file: convertedImage,
        mimetype: file[0].file.type,
        filename: file[0].file.name,
      })
    );
  }

  useEffect(() => {
    if (!uploadedData) {
      return;
    }

    changeStateForm(setForm, form, 'field_image', uploadedData.fid[0].value);
  }, [uploadedData]);

  useEffect(() => {
    !isEdit
      ? !newCampaignData
        ? changeStateForm(setForm, form, 'country', localStorage.getItem('country'))
        : changeStateForm(
            setForm,
            form,
            'country',
            String(countriesPromo?.find((c) => c.name === newCampaignData?.country)?.id)
          )
      : changeStateForm(
          setForm,
          form,
          'country',
          String(countriesPromo?.find((c) => c.name === state?.campaignDetailData?.country)?.id)
        );
  }, [brandData, categoryData, campaignData]);

  const checkStartDate = (startDate: string, endDate: string) => {
    const startTimestamp = moment(startDate).format('X');
    const endTimestamp = moment(endDate).format('X');
    const nowTimestamp = moment(new Date()).format('X');

    if (startTimestamp <= nowTimestamp) {
      addToast({
        message: "Start date cannot be less than or equal to today's date.",
        type: 'error',
      });
      return false;
    } else if (startTimestamp >= endTimestamp && endDate) {
      addToast({
        message: "Start date cannot be greater than or equal to end's date.",
        type: 'error',
      });
      setForm({
        ...form,
        period: {
          start: null,
          end: null,
        },
      });
      return false;
    } else {
      return true;
    }
  };

  const checkEndDate = (startDate: string, endDate: string) => {
    const startTimestamp = moment(startDate).format('X');
    const endTimestamp = moment(endDate).format('X');
    const nowTimestamp = moment(new Date()).format('X');

    if (endTimestamp <= nowTimestamp) {
      addToast({
        message: "The end date cannot be less than or equal to today's date.",
        type: 'error',
      });
      return false;
    } else if (endTimestamp <= startTimestamp && startDate) {
      addToast({
        message: "The end date cannot be less than or equal to start's date.",
        type: 'error',
      });
    } else {
      return true;
    }
  };

  function checkValidationErrors() {
    const errors = [];
    if (validationData) {
      for (let errorKey in validationData) {
        if (errorKey === 'ValidationErrors') continue
        if (validationData[errorKey]) {
          errors.push(errorKey);
        }
      }
    }
    return errors;
  }

  const handleToggleStatusChange = (checked: boolean) => {
    const newStatus = checked ? 'Published' : 'Unpublished';

    const validationErrors = checkValidationErrors();
    if (validationErrors.length > 0 || form?.rewards_integration === true && challenges?.length === 0 && checked === true) {
      addToast({
        message: 'Unable to publish the campaign.',
        type: 'error',
      });
      return;
    }

    if (!checked) {
      addToast({
        message: `Campaign status changed to ${newStatus}.`,
        type: 'warning',
      });
    } else {
      setToastAfterModal({
        message: `Campaign status changed to ${newStatus}.`,
        type: 'success',
      });
      setShowModal(true)
    }

    setForm(prevForm => ({
      ...prevForm,
      status: newStatus,
    }));
  };

  const handleModalClose = () => {
    setShowModal(false)
    setForm(prevForm => ({
      ...prevForm,
      status: 'Unpublished',
    }))
  }

  const handleModalSave = async() => {
    const updatedForm = {
      ...form,
      accept_terms: true,
      consent_to_usage: true
    };

    await dispatch(PATCH_CAMPAIGN_DETAILS(updatedForm));

    setShowModal(false)

    setForm(prevForm => ({
      ...prevForm,
      status: 'Published',
    }))

    if (toastAfterModal) {
      addToast(toastAfterModal)
      setToastAfterModal(null)
    }
  }

  return (brandData && categoryData && form) || (brandData && categoryData && isCreateForm) ? (
    <Grid.Container type="fluid" style={{ rowGap: '1rem', marginBottom: '1rem' }}>
      <OptInModal
      isOpen={showModal}
      onClose={() => handleModalClose()}
      onSave={() => handleModalSave()}
      />
      {!isEdit && (
        <div style={{ marginLeft: '6px' }}>
          <PageTitle title={'Create Campaign'} hint="Give this campaign a unique name." />
        </div>
      )}
      {isEdit &&
        validationData &&
        Object.keys(validationData).map((errorKey) => {
          if (errorKey === 'time_window_total' || errorKey === 'ValidationErrors') {
            return null;
          }
          return (
            <StyledAlert
              message={warningMessages[errorKey] || errorKey}
              type={'warning'}
              width="100%"
            />
          );
        })}
        {form?.rewards_integration === true && challenges?.length === 0 ? <StyledAlert
          message={"There are no registered challenges for this campaign. It is not possible to publish a campaign with Rewards Integration without registered challenges."}
          type={'warning'}
          width="100%"
        /> : null}
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <InfoCardTitle size="H4" title={'Period'} />
      </Grid.Item>
      <Grid.Item xl={3} lg={3} md={6} sm={4} xs={12}>
        <StyledInput
          value={form?.period?.start || ''}
          type="datetime-local"
          size="large"
          label="Start date*"
          placeholder="Insert the date"
          width={'100%'}
          required
          className="calendars"
          onChange={(e) => {
            checkStartDate(e.currentTarget.value, form?.period?.end) === true
              ? setForm({
                  ...form,
                  period: {
                    ...form?.period,
                    start: e.currentTarget.value,
                  },
                })
              : setForm({
                  ...form,
                  period: {
                    ...form?.period,
                    start: null,
                  },
                });
          }}
          hasError={stepIncompleted && !form?.period?.start}
          errorText="Start is required."
        />
      </Grid.Item>
      <Grid.Item xl={3} lg={3} md={6} sm={4} xs={12}>
        <StyledInput
          value={form?.period?.end || ''}
          type="datetime-local"
          size="large"
          label="End date*"
          placeholder="Insert the date"
          width={'100%'}
          required
          onChange={(e) => {
            checkEndDate(form?.period?.start, e.currentTarget.value) === true
              ? setForm({
                  ...form,
                  period: {
                    ...form?.period,
                    end: e.currentTarget.value,
                  },
                })
              : setForm({
                  ...form,
                  period: {
                    ...form?.period,
                    end: null,
                  },
                });
          }}
          hasError={stepIncompleted && !form?.period?.end}
          errorText="End is required."
        />
      </Grid.Item>
      {/* ---------------CAMPAIGN SETTINGS--------------- */}
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <FlexContainer
          width="100%"
          display="inline-flex"
          justifyContent={isEdit ? 'space-between' : 'flex-start'}
          alignItems="center"
        >
          <InfoCardTitle size="H4" title="Campaign settings" />
          {isEdit && (
            <FlexContainer
              display="inline-flex"
              gap="0.5rem"
              minWidth="150px"
              justifyContent="flex-start"
            >
              {validationData && (
                <Toggle
                  checked={form?.status === 'Published' ? true : false}
                  onChange={(event) => {}}
                  onCheckedChange={handleToggleStatusChange}
                />
              )}
              <Paragraph style={{ fontSize: isAugmentedScale ? '0.9rem' : '1rem' }}>
                {form?.status}
              </Paragraph>
            </FlexContainer>
          )}
        </FlexContainer>
        <br />
      </Grid.Item>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <Grid.Container
          type="fluid"
          style={{ width: '100%', height: 'max-content', rowGap: '1rem', margin: 0 }}
        >
          <Grid.Item xl={6} lg={4} md={5} sm={4} xs={12}>
            <StyledInput
              defaultValue={form?.name}
              value={form?.name}
              required
              size="large"
              label="Campaign name*"
              placeholder="Insert your text here"
              width={'100%'}
              onChange={(e) => changeStateForm(setForm, form, 'name', e.currentTarget.value)}
              hasError={stepIncompleted && !form?.name}
              errorText="Name is required."
            />
          </Grid.Item>
          <Grid.Item xl={3} lg={4} md={7} sm={4} xs={12}>
            <StyledSelect
              error={stepIncompleted && !form?.country ? 'Country is required' : ''}
              value={form?.country}
              size="large"
              label="Country*"
              width={'100%'}
              onChange={(value) => {
                changeStateForm(setForm, form, 'country', value)
              }}
            >
              {countriesPromo &&
                countriesPromo.map((country: countryType, index: number) => {
                  return (
                    <Select.Option key={country.name + index} value={String(country.id)}>
                      {country.name}
                    </Select.Option>
                  );
                })}
            </StyledSelect>
          </Grid.Item>
          <Grid.Item xl={4} lg={4} md={2} sm={4} xs={12}>
            <StyledSelect
              error={stepIncompleted && !form?.brand ? 'Brand is required' : ''}
              value={form?.brand?.target_id?.toString() + ',' + form?.brand?.name?.toString()}
              width={'100%'}
              size="large"
              label="Brand*"
              placeholder="select"
              onChange={(value: string) => {
                let vals = value.split(',');

                setForm({
                  ...form,
                  brand: {
                    target_id: Number(vals[0]),
                    name: vals[1],
                  },
                });
              }}
            >
              {brandData &&
                brandData.map((brand: brandType, index: number) => {
                  return (
                    <Select.Option
                      key={brand.name[0].value + index}
                      value={brand.tid[0].value.toString() + ',' + brand.name[0].value}
                    >
                      {brand.name[0].value}
                    </Select.Option>
                  );
                })}
            </StyledSelect>
          </Grid.Item>
          <Grid.Item xl={4} lg={4} md={2} sm={4} xs={12}>
            <StyledSelect
              error={stepIncompleted && !form?.category ? 'Category is required' : ''}
              value={form?.category?.target_id?.toString() + ',' + form?.category?.name?.toString()}
              size="large"
              width={'100%'}
              label="Campaign type*"
              placeholder="select"
              onChange={(value: string) => {
                let vals = value.split(',');

                setForm({
                  ...form,
                  category: {
                    target_id: Number(vals[0]),
                    name: vals[1],
                  },
                });
              }}
            >
              {categoryData &&
                categoryData.map((category: categoryType, index: number) => {
                  return (
                    <Select.Option
                      key={category.name[0].value + index}
                      value={category.tid[0].value + ',' + category.name[0].value}
                    >
                      {category.name[0].value}
                    </Select.Option>
                  );
                })}
            </StyledSelect>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      {/*
        <Grid.Item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ flexDirection: 'column', margin: '1.3rem 0px' }}
        >
          <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
            <Toggle
              defaultChecked={false}
              onCheckedChange={(check) => {
                setForm({
                  ...form,
                  rewards_integration: check
                });
              }}
              checked={form?.rewards_integration}
            />
            <Paragraph
              size="basis"
              type="body"
              style={{
                fontSize: isAugmentedScale ? '0.9rem' : '1rem',
                color: '#141414',
                marginLeft: '8px',
              }}
            >
              Rewards integration
            </Paragraph>
          </div>
          <Paragraph
            size="small"
            type="body"
            style={{
              fontSize: isAugmentedScale ? '0.75rem' : '0.875rem',
              color: '#757575',
            }}
          >
            Enable rewards integration for current campaign.
          </Paragraph>

        </Grid.Item>
          */}
      {form?.type?.target_id === 'penalty_kick' ||
      form?.type?.target_id === 'instant_card' ||
      form?.type?.target_id === 'spinning_wheel' ? (
        <Grid.Item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={6}
          style={{ flexDirection: 'column', justifyContent: 'center' }}
        >
          <StyledToggle
            value={form.lucky_number_status}
            onChange={(val: boolean) => {
              changeStateForm(setForm, form, 'lucky_number_status', val);
            }}
            title="Lucky number enabled"
            description="Status ON indicates that the lucky number generation mechanism is enabled for the
          current campaign."
          />
        </Grid.Item>
      ) : null}
{/*       {form?.type?.target_id === 'bee_run' && !isEdit ? (
        <Grid.Item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={6}
          style={{ flexDirection: 'column', justifyContent: 'center' }}
        >
          <StyledToggle
            value={form.bee_run_prizes}
            onChange={(val: boolean) => {
              changeStateForm(setForm, form, 'bee_run_prizes', val);
            }}
            title="Enable Instant Prizes"
          />
        </Grid.Item>
      ) : null} */}
      {form?.type?.target_id === 'bottle_crush' && (
        <>
          <FlexContainer
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-start"
            gap="1rem"
            padding="0 8px"
          >
            <StyledInput
              defaultValue={form?.name_mix_and_match}
              value={form?.name_mix_and_match}
              type="text"
              min={1}
              minLength={1}
              required
              label="Entity name*"
              hint="The name of the Mix and Match entity."
              placeholder="Insert your text here"
              onChange={(e) =>
                changeStateForm(setForm, form, 'name_mix_and_match', e.currentTarget.value)
              }
              hasError={stepIncompleted && !form?.name_mix_and_match}
              errorText="Name Mix and Match is required."
            />
            <QuantifierLabeled
              title="Player progress expiration time*"
              labelText="The player progress expiration time, in seconds."
              defaultValue={form?.player_progress_expiration_time}
              value={form?.player_progress_expiration_time}
              min={1}
              onChange={(e) => {
                let v = parseInt(e.currentTarget.value, 10);
                if (isNaN(v)) {
                  v = 1;
                }
                v = v <= 0 ? 1 : v;
                changeStateForm(setForm, form, 'player_progress_expiration_time', v);
              }}
              onClickPlusButton={() => {
                changeStateForm(setForm, form, 'player_progress_expiration_time', form.player_progress_expiration_time + 1)
              }}
              onClickMinusButton={() => {
                changeStateForm(setForm, form, 'player_progress_expiration_time', form.player_progress_expiration_time - 1)
              }}
            />
            <FlexContainer
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-start"
              alignItems="flex-start"
              gap={'0.5rem'}
              flexDirection="column"
            >
              <FlexContainer
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="0.5rem"
                width="max-content"
              >
                <Toggle
                  checked={form?.newgame}
                  onClick={() => changeStateForm(setForm, form, 'newgame', !form?.newgame)}
                />
                <Paragraph
                  css={{ cursor: 'pointer' }}
                  onClick={() => changeStateForm(setForm, form, 'newgame', !form?.newgame)}
                >
                  New game +
                </Paragraph>
              </FlexContainer>
              <StyledParagraph
                css={{ color: '#0000008c', fontSize: '12px' }}
                className="label"
                type="body"
                title="By checking the option, the player will be able to start a new game after completing
                all the levels with the same attempt."
              ></StyledParagraph>
            </FlexContainer>
          </FlexContainer>
        </>
      )}
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ flexDirection: 'column' }}>
        <StyledToggle
          title="Rewards integration"
          description="Enable rewards integration for current campaign."
          defaultChecked={form?.rewards_integration}
          onChange={(check: boolean) => {
            setForm({
              ...form,
              rewards_integration: check,
            });
          }}
          value={form?.rewards_integration}
        />
      </Grid.Item>
    </Grid.Container>
  ) : null;
};
export default EditCampaignModule;
