import { Card } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import StyledFilter from '../../components/Filter/Filter';
import FlexContainer from '../../components/FlexContainer';
import StyledTable, { StyledPagination } from '../../components/Table/Table';
import handleFilter from '../campaignDetails/extra/filter';
import QuizHeaders from '../json/columnHeaders/QuizHeaders.json';
import PageTitle from '../../components/PageTitle/PageTitle';
import useQuizHandler from '../../hook/useQuizHandler';
import { getJsonApiData } from '../../utils/formatData';

const Quiz: React.FC = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAllQuizzes } = useQuizHandler();
  const [requestData, setRequestData] = useState({
    total: 0,
    page: 0,
    limit: 25,
    search: ''
  });

  const fetchData = async () => {
    setLoading(true);
    await getAllQuizzes(requestData)
      .then(async (res) => {
        const formatData = getJsonApiData(res);

        const transformedData = formatData.data.map(item => {
          let statusColor = item.status === true ? '#00A838' : '#E4423F';

          return {
            id: item.drupal_internal__id,
            uuid: item.uuidEntity,
            title: item.name,
            published: (
              <div
                style={{
                  display: 'flex',
                  gap: '0.5rem',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '10rem',
                    backgroundColor: statusColor,
                  }}
                />
                <div>{item.status === true ? 'On' : 'Off'}</div>
              </div>
            ),
            published_value: item.status === true ? 'On' : 'Off',
            description: item.field_description,
            last_edit: item.changed,
            show_cover_image: item.field_show_cover_image,
            cover_image: item.uri?.url,
          };
        });

        setData(transformedData);
        setFilteredData(transformedData);
        setRequestData(prevState => ({ ...prevState, total: formatData.total }))
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePagination = (page, limit) => {
    requestData.limit = limit
    requestData.page = page >= 1 ? page - 1 : page
    fetchData();
  };

  const handleSearch = (e) => {
    const searchValue = e.currentTarget.value;
    setSearch(searchValue);

    if (searchValue) {
      const searchKeys = ['id', 'title', 'published_value'];
      handleFilter(searchValue, data, searchKeys, setFilteredData);
    } else {
      setFilteredData(data);
    }
  };

  return (
    <>
      <PageTitle marginBottom="1rem" size="H2" title="Quiz" />
      <Card elevated={'medium'} border={'large'} >
        <FlexContainer
          width="100%"
          display={'inline-flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              value={search}
              onChange={handleSearch}
              onClear={handleSearch}
            />
          </div>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            onClick={() => navigate(`/bees-games/quiz/add-quiz`)}
          >
            Add Quiz
          </StyledButton>
        </FlexContainer>
        <StyledTable
          columns={QuizHeaders}
          search={false}
          data={filteredData || []}
          onRow={(data) => ({
            onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
              navigate('/bees-games/quiz/quiz-details/' + data.id);
            },
          })}
          loading={loading}
          pagination={false}
        />
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          padding={'0.5rem 1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            onChange={handlePagination}
          />
        </FlexContainer>
      </Card>
    </>
  );
};

export default Quiz;
