import { Tabs, Toast } from '@hexa-ui/components';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledTabs from '../../components/Tabs/Tabs';
import { AlertContext } from '../../contexts/alert.context';
import QuizDetailTabs from './extra/quizDetailTabs';

const QuizDetails = () => {
  const { addToast } = useContext(AlertContext);

  const { state } = useLocation();
  const [showToast, setShowToast] = useState(false);
  const [toastParams, setToastParams] = useState<{
    message: string;
    type: 'info' | 'error' | 'warning' | 'success';
  }>({
    message: '',
    type: 'info',
  });
  const { hash } = useLocation();

  useEffect(() => {
    if (state?.toast) {
      addToast({
        message: state?.toast?.message,
        type: state?.toast?.type || 'info',
      });
    }
  }, []);

  const tabsContentCSS: CSSProperties = {
    backgroundColor: 'transparent',
    padding: '1.6rem 0 0 0',
  };

  return (
    <div>
      <PageTitle marginBottom="1.2rem" title="Quiz details" />
      <StyledTabs defaultValue={hash || '#quiz'}>
        <Tabs.List>
          {QuizDetailTabs?.tabs.map((tab, index) => {
            return (
              <a
                key={index + tab.title}
                style={{ textDecoration: 'none', color: 'inherit' }}
                href={tab.hash}
              >
                <Tabs.Trigger key={index} value={tab.hash}>
                  {tab.title}
                </Tabs.Trigger>
              </a>
            )
          })}
        </Tabs.List>
        {QuizDetailTabs?.tabs.map((tab, index) => (
          <Tabs.Content key={index} value={tab?.hash} style={tabsContentCSS}>
            <tab.component />
          </Tabs.Content>
        ))}
      </StyledTabs>
      <Toast.Provider duration={3000} swipeDirection="right">
        <Toast.Root
          open={showToast}
          message={toastParams.message}
          type={toastParams.type || 'info'}
          position={'top-right'}
          showCloseButton
        />
      </Toast.Provider>
    </div>
  );
};
export default QuizDetails;
