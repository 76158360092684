import { FC } from 'react';
import EditLevel from './modules/editLevel';
import GenerateLevels from './modules/generateLevels';
import ImportAccounts from './pages/ImportAccounts/ImportAccounts';
import AddChallenge from './pages/ImportChallenges/addChallenge';
import ImportChallenges from './pages/ImportChallenges/importChallenges';
import { PrizeCreation } from './pages/PrizeCreation/PrizeCreation';
import { PrizeDetails } from './pages/PrizeDetails/PrizeDetails';
import TimeWindowForm from './pages/TimeWindow/TimeWindowForm';
import AccountList from './pages/accountList/accountList';
import EditAccontList from './pages/accountList/editAccountList';
import AddPlayer from './pages/addPlayer/addPlayer';
import AddPrize from './pages/addPrize/addPrize';
import CampaignDetails from './pages/campaignDetails/CampaignDetails';
import CreateCampaign from './pages/createCampaign/CreateCampaign';
import CreateRanking from './pages/createRanking/CreateRankings';
import EditCampaign from './pages/editCampaign/EditCampaign';
import EditPlayer from './pages/editPlayer/editPlayer';
import EditPrize from './pages/editPrize/editPrize';
import EditRanking from './pages/editRanking/EditRankings';
import ExportList from './pages/exportList';
import ExportListNoCampaign from './pages/exportList/exportListNoCampaign';
import ExtensionAccount from './pages/extensionAccount/extensionAccount';
import Home from './pages/home/home';
import ImportDigitalPrizesCodes from './pages/importDigitalPrizesCodes/ImportDigitalPrizesCodes';
import ImportLuckyNumbers from './pages/importLuckyNumbers/ImportLuckyNumbers';
import ImportPlayers from './pages/importPlayers/ImportPlayers';
import ManageAssets from './pages/manageassets/ManageAssets';
import ImportList from './pages/importList';
import ChallengeStatus from './pages/accountList/challengeStatus/challengeStatus';
import EditCustomSettings from './pages/editCustomSettings/EditCustomSettings';
import ChancesReset from './pages/chancesReset/chancesReset';
import EditChances from './pages/editChances/editChances';
import QuizPage from './pages/quiz/Quiz';
import AddQuiz from './pages/quiz/AddQuiz';
import EditQuiz from './pages/quiz/EditQuiz';
import QuizDetails from './pages/quiz/QuizDetails';
import AddResult from './pages/quiz/AddResult';
import EditResult from './pages/quiz/EditResult';
import AddQuestion from './pages/quiz/AddQuestion';
import EditQuestion from './pages/quiz/EditQuestion';

export interface NavProps {
  path: string;
  page: FC;
}

const _routes: Array<NavProps> = [
  {
    path: `/bees-games/`,
    page: Home,
  },
  {
    path: `/bees-games/manage-assets`,
    page: ManageAssets,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id`,
    page: CampaignDetails,
  },
  {
    path: `/bees-games/manage-assets/edit-campaign/:id`,
    page: EditCampaign,
  },
  {
    path: `/bees-games/Add-asset`,
    page: CreateCampaign,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/add-player`,
    page: AddPlayer,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/import-players`,
    page: ImportPlayers,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/edit-player/:id`,
    page: EditPlayer,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/time-window`,
    page: TimeWindowForm,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/time-window/:id`,
    page: TimeWindowForm,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/import-digital-prizes-codes`,
    page: ImportDigitalPrizesCodes,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/import-lucky-numbers`,
    page: ImportLuckyNumbers,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id/chances-reset`,
    page: ChancesReset,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/edit-chances/:id`,
    page: EditChances,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id/add-prize`,
    page: AddPrize,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/edit-prize/:id`,
    page: EditPrize,
  },
  {
    path: `/bees-games/manage-assets/create-campaign`,
    page: CreateCampaign,
  },
  {
    path: `/bees-games/manage-assets/create-prize`,
    page: PrizeCreation,
  },
  {
    path: `/bees-games/manage-assets/prize-details/edit/:id`,
    page: PrizeCreation,
  },
  {
    path: `/bees-games/manage-assets/prize-details/:id`,
    page: PrizeDetails,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id/edit`,
    page: EditCampaign,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id/generate-levels`,
    page: GenerateLevels,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id/edit-level/:idLevel`,
    page: EditLevel,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/add-challenge`,
    page: AddChallenge,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:campaignId/import-challenges`,
    page: ImportChallenges,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id/export/:bundle`,
    page: ExportList,
  },
  {
    path: `/bees-games/export/:bundle`,
    page: ExportListNoCampaign,
  },
  {
    path: `/bees-games/account-list`,
    page: AccountList,
  },
  {
    path: `/bees-games/account-list/challenge-status/:idAccount`,
    page: ChallengeStatus,
  },
  {
    path: `/bees-games/account-list/import-accounts`,
    page: ImportAccounts,
  },
  {
    path: `/bees-games/:page/import/:bundle`,
    page: ImportList,
  },
  {
    path: `/bees-games/account-list/extension-account`,
    page: ExtensionAccount,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id/create-ranking`,
    page: CreateRanking,
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id/edit-ranking/:idRanking`,
    page: EditRanking,
  },
  {
    path: `/bees-games/account-list/edit-account-list`,
    page: EditAccontList,
  },
  {
    path: `/bees-games/quiz`,
    page: QuizPage,
  },
  {
    path: `/bees-games/quiz/quiz-details/:id`,
    page: QuizDetails,
  },
  {
    path: `/bees-games/quiz/add-quiz`,
    page: AddQuiz,
  },
  {
    path: `/bees-games/quiz/quiz-details/:id/edit`,
    page: EditQuiz,
  },
  {
    path: `/bees-games/quiz/quiz-details/:id/add-result`,
    page: AddResult,
  },
  {
    path: `/bees-games/quiz/quiz-details/:id/edit-result/:idResult`,
    page: EditResult,
  },
  {
    path: `/bees-games/quiz/quiz-details/:id/add-question`,
    page: AddQuestion,
  },
  {
    path: `/bees-games/quiz/quiz-details/:id/edit-question/:idQuestion`,
    page: EditQuestion
  },
  {
    path: `/bees-games/manage-assets/campaign-details/:id/edit-custom-settings`,
    page: EditCustomSettings,
  },
];

export default _routes;
