import { Card, Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import handleFilter from '../../campaignDetails/extra/filter';
import QuizHeaders from '../../json/columnHeaders/QuizQuestionsHeaders.json';
import PageTitle from '../../../components/PageTitle/PageTitle';
import useQuizHandler from '../../../hook/useQuizHandler';

const QuestionsTab: React.FC = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;
  const [data, setData] = useState<any>([]);
  const [quizData, setQuizData] = useState<any>([]);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getQuiz, getAllQuestions, deleteQuestion } = useQuizHandler();
  const [requestData, setRequestData] = useState({
    total: 0,
    page: 0,
    limit: 25,
    search: '',
    id: id
  })

  const fetchData = async () => {
    setLoading(true);

    try {
      const [resultQuestion, quizRes] = await Promise.all([
        getAllQuestions(requestData),
        getQuiz(requestData)
      ]);

      setQuizData({
        campaign_name: quizRes.data[0]?.attributes.name,
        campaign_uuid: quizRes.data[0]?.id
      });

      const includedMap = {};
      resultQuestion.included?.forEach(item => {
        includedMap[item.id] = item;
      });

      const getFieldData = (field, relationships) => {
        const fieldData = relationships[field]?.data;
        if (Array.isArray(fieldData)) {
          return fieldData.map(data => {
            const item = includedMap[data.id];
            if (item) {
              return {
                id: item.id,
                field_title: item.attributes.field_title,
                field_description: item.attributes.field_description,
                image: item.relationships?.field_image?.data
                  ? {
                      id: includedMap[item.relationships.field_image.data.id]?.id,
                      url: includedMap[item.relationships.field_image.data.id]?.attributes.uri.url
                    }
                  : null
              };
            }
            return null;
          }).filter(Boolean);
        } else if (fieldData) {
          const item = includedMap[fieldData.id];
          if (item) {
            return {
              id: item.id,
              field_title: item.attributes.field_title,
              field_description: item.attributes.field_description,
              image: item.relationships?.field_image?.data
                ? {
                    id: includedMap[item.relationships.field_image.data.id]?.id,
                    url: includedMap[item.relationships.field_image.data.id]?.attributes.uri.url
                  }
                : null
            };
          }
        }
        return null;
      };

      const getFieldResultsData = (relationships) => {
        const fieldData = relationships['field_results']?.data;
        if (Array.isArray(fieldData) && fieldData.length > 0) {
          const data = fieldData[0];
          const resultItem = includedMap[data.id];
          if (resultItem) {
            const resultData = includedMap[resultItem.relationships?.field_result?.data.id];
            return {
              result: resultData?.id,
              result_id: data.id,
              result_name: resultData?.attributes.name,
              field_points: resultItem.attributes.field_points
            };
          }
        }
        return null;
      };

      const getFieldAnswersData = (relationships) => {
        const fieldData = relationships['field_answers']?.data;
        if (Array.isArray(fieldData) && fieldData.length > 0) {
          const data = fieldData[0];
          const item = includedMap[data.id];
          if (item) {
            const fieldAnswerData = item.relationships?.field_answer?.data;
            const fieldAnswer = fieldAnswerData
              ? includedMap[fieldAnswerData.id]
              : null;
            const fieldResults = getFieldResultsData(item.relationships);
            return fieldAnswer
              ? {
                  id: fieldAnswer.id,
                  field_title: fieldAnswer.attributes.field_title,
                  field_description: fieldAnswer.attributes.field_description,
                  image: fieldAnswer.relationships?.field_image?.data
                    ? {
                        id: includedMap[fieldAnswer.relationships.field_image.data.id]?.id,
                        url: includedMap[fieldAnswer.relationships.field_image.data.id]?.attributes.uri.url
                      }
                    : null,
                  results: fieldResults
                }
              : null;
          }
        }
        return null;
      };

      const formatData = resultQuestion.data.map(item => {
        const { relationships, attributes, id } = item;

        const field_answers = getFieldAnswersData(relationships);
        const field_feedback = getFieldData('field_feedback', relationships);
        const field_question: any = getFieldData('field_question', relationships);

        return {
          campaign_name: quizRes.data[0]?.attributes.name,
          campaign_uuid: quizRes.data[0]?.id,
          parent_id: attributes.drupal_internal__id,
          field_type: attributes.field_type,
          uuidEntity: id,
          field_title: field_question?.field_title,
          field_answers,
          field_feedback,
          field_question,
        };
      });

      const updateData = InsertActions(formatData);
      setData(updateData);
      setFilteredData(updateData);

      setRequestData(prevState => ({ ...prevState, total: formatData.length }));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePagination = (page, limit) => {
    requestData.limit = limit
    requestData.page = page >= 1 ? page - 1 : page
    fetchData();
  };

  const handleSearch = (e) => {
    const searchValue = e.currentTarget.value;
    setSearch(searchValue);

    if (searchValue) {
      const searchKeys = ['parent_id', 'field_title'];
      handleFilter(searchValue, data, searchKeys, setFilteredData);
    } else {
      setFilteredData(data);
    }
  };

  const handleDelete = (uuidEntity) => {
    deleteQuestion(uuidEntity).finally(() => fetchData());
  }

  const InsertActions = (data) => {
    let aux = [];

    const actions = (data) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <>
          <IconButton
            icon={Edit2}
            variant="inherit"
            onClick={() => {
              navigate(
                `/bees-games/quiz/quiz-details/${id}/edit-question/${data.parent_id}`,
                {
                  state: data,
                }
              );
            }}
          />
          <Modal.Root
            actions={
              <FlexContainer
                width="100%"
                display="inline-flex"
                justifyContent="flex-end"
                gap="0.5rem"
              >
                <Modal.Action>
                  <StyledButton variant="secondary">Cancel</StyledButton>
                </Modal.Action>
                <Modal.Action>
                  <StyledButton variant="destructive" onClick={() => handleDelete(data.uuidEntity)}>
                    Delete
                  </StyledButton>
                </Modal.Action>
              </FlexContainer>
            }
            trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => { }} />}
            variant="overlay"
            destructive="critical"
          >
            <Heading type="H3" css={{ marginBottom: '1rem' }}>
              Delete question?
            </Heading>
            <Paragraph>{'Do you really wish to delete this question?'}</Paragraph>
            <Paragraph>{"This action can't be undone."}</Paragraph>
          </Modal.Root>
        </>
      </div>
    );

    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item),
      });
    }
    return aux;
  };

  return (
    <>
      <PageTitle marginBottom="1rem" size="H2" title="Questions and Answers" />
      <Card elevated={'medium'} border={'large'} >
        <FlexContainer
          width="100%"
          display={'inline-flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              value={search}
              onChange={handleSearch}
              onClear={handleSearch}
            />
          </div>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            isLoading={loading}
            onClick={() => navigate(`/bees-games/quiz/quiz-details/${requestData.id}/add-question`,
              {
                state: {
                  campaign_name: quizData.campaign_name,
                  campaign_uuid: quizData.campaign_uuid
                }
              }
            )}
          >
            Add Question
          </StyledButton>
        </FlexContainer>
        <StyledTable
          columns={QuizHeaders}
          search={false}
          data={filteredData || []}
          loading={loading}
          pagination={false}
        />
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          padding={'0.5rem 1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            onChange={handlePagination}
          />
        </FlexContainer>
      </Card>
    </>
  );
};

export default QuestionsTab;
